import React from "react";

const Navbar = () => {

  return (
    <>
      <nav className="h-[80px] w-full bg-white shadow-md fixed">
          <div className="container mx-auto flex justify-center items-center h-full ">
              <img src="/images/logo.png" alt={"logo"} />
          </div>
      </nav>
    </>
  );
}

export default Navbar;
