import React, { useState } from 'react';

const InputField = ({children, name, label, type="text", status = "primary", value = "", onChange = null, error = false}) => {

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
     <input 
        id={name ?? "default"} 
        type={type}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          if (!value) setIsFocused(false);
        }}
        name={name ?? "default"} 
        className={
              `focus:outline-none ${value !== "" ? "border-b-2 border-green-700" : "border-b border-gray-500"} rounded-t-lg w-full  transition-colors py-2 peer px-4  ${status === "primary" ? "input-primary" : status === "error"  ?" input-error" : ""} bg-gray-50 `
        }
        onChange={onChange}
       />
      <label 
        htmlFor={name ?? "default"} 
        className={`absolute left-0 top-1  transition-all ease-in-out ${isFocused || value !== "" ? "text-xs -top-4 text-green-700" : "" } ${status==="primary" ? "peer-focus:text-green-700" : "peer-focus:text-red-700"} text-gray-600 px-4`}>
          {label ?? "Label"}
      </label>
    </div>
  );
};

export default InputField;
