import React from 'react'
import { BiArrowBack } from "react-icons/bi";

function Pagination({ currentPage, totalPages, onPageChange, handleChangeCurrentPage }) {
   const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  let startPage, endPage;
  if (totalPages <= 10) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= 6) {
      startPage = 1;
      endPage = 10;
    } else if (currentPage + 4 >= totalPages) {
      startPage = totalPages - 9;
      endPage = totalPages;
    } else {
      startPage = currentPage - 5;
      endPage = currentPage + 4;
    }
  }

  return (
    <>
      <div id="pagination" className="w-full py-5 bg-gray-200 flex items-center border-t justify-between border-gray-300 px-8">
            <div className="flex items-center space-x-2 text-gray-700 text-sm">
               <span className="block">Show</span>
               <select onChange={handleChangeCurrentPage} name="perpage" id="perpage" className="px-6 py-2 rounded appearance-none shadow cursor-pointer">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
               </select>
            </div>
            <ul className="flex items-center text-sm rounded shadow bg-white">
              <button 
                disabled={currentPage === 1}
                onClick={() => onPageChange(currentPage - 1)} className="px-4 "
              >
                <BiArrowBack className="text-gray-400" />
              </button>
               {startPage > 1 && <span className="px-3 py-1">...</span>}
               {pages.slice(startPage - 1, endPage).map((page) => (
                     <li
                      key={page} 
                      onClick={() => onPageChange(page)}
                      className={`pagination-item hover:bg-gray-200 ${page === currentPage ? "text-cerulean font-bold hover:bg-gray-200" : ""}`}>  {page}
                      </li>
                  ))
               }
               {endPage < totalPages && <span className="px-3 py-1">...</span>}
               <button 
                  className="px-4"
                  disabled={currentPage === totalPages}
                  onClick={() => onPageChange(currentPage + 1)
                }
                >
                  <BiArrowBack className="rotate-180 text-gray-400 " />
               </button>
            </ul>
         </div>
    </>
  )
}

export default Pagination