
import Loading from "./Loading"

const LoadingScreen = () => {
	return(
		<div className="fixed top-0 right-0 bottom-0 left-0 bg-black z-50 opacity-50 flex items-center justify-center">
			<Loading />
		</div>
	)
}

export default LoadingScreen;