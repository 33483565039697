import React, {useRef, useEffect, Fragment} from 'react'
import { BiDotsVertical } from "react-icons/bi";
import { Menu, Transition } from '@headlessui/react'

function RowData({data, index, showModalConfirm, setIdDeleteSelected, handleEdit}) {
   const popup = useRef();

   useEffect(() => {
      function handleClickOutside(event) {
        if (popup.current && !popup.current.contains(event.target)) {
         }
      }
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }, [popup]);

   const handleClickDelete = id => {
      showModalConfirm()
      setIdDeleteSelected(id)
   }

   const handleSendMessage = () => {
      let message = `${data?.nama_agenda}, di ${data?.tempat}, pada ${data?.tanggal_indonesia}, jam ${data?.jam}`;
      const url = `https://api.whatsapp.com/send?text=${message}`

      window.open(url)
   }

  return (
   <>
      <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
         <th scope="row" className="px-6 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            {index}
         </th>
         <td className="px-6 py-0">
            {data?.nama_agenda}
         </td>
         <td className="px-6 py-0">
            {data?.tempat}
         </td>
         <td className="px-6 py-0" width={200}>
           {data?.tanggal_indonesia}
         </td>
         <td className="px-6 py-0" width={200}>
            {data?.jam}
         </td>
         <td className="px-6 py-0 text-right relative">
            <Menu as="div">
               <Menu.Button><BiDotsVertical className="w-4 h-4" /></Menu.Button>
               <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
               >
               <Menu.Items as="div" className={'absolute flex flex-col text-xs rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none px-1 py-1 z-20 w-40 right-10 text-left top-5 '}>
                  <Menu.Item >
                     {({ active }) => (
                        <div
                        onClick={() => handleEdit(data?.id)}
                        className={`px-3 py-2 ${active && 'bg-gray-200'}  cursor-pointer font-normal leading-relaxed`}
                        >
                        Ubah
                        </div>
                     )}
                  </Menu.Item>
                  <Menu.Item>
                     {({ active }) => (
                        <div
                        onClick={() => handleClickDelete(data.id)}
                        className={`px-3 py-2 ${active && 'bg-gray-200'} cursor-pointer font-normal leading-relaxed`}
                        >
                        Hapus
                        </div>
                     )}
                  </Menu.Item>
                  <Menu.Item>
                  {({ active }) => (
                        <div
                        onClick={handleSendMessage}
                        className={`px-3 py-2 ${active && 'bg-gray-200'} cursor-pointer font-normal leading-relaxed`}
                        >
                        Kirim ke WA
                        </div>
                     )}
                  </Menu.Item>
               </Menu.Items>
            </Transition>
            </Menu>
         </td>
      </tr>
   </>
  )
}

export default RowData