import React, { useState } from 'react';

const TextareaField = ({children, name, label, type="text", status = "primary", value = "", onChange = null, rows=6}) => {

  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
     <textarea 
        style={{resize: "none"}} 
        rows={rows} 
        id={name ?? "default"} 
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          if (!value) setIsFocused(false);
        }} 
        name={name ?? "default"}
        value={value}
        onChange={onChange}
        className={`focus:outline-none w-full ${value !== "" ? "border-b-2 border-green-700" : "border-b border-gray-500 bg-gray-50 "} bg-gray-50 rounded-t-lg transition-colors py-2 peer px-4 ${status === "primary" ? "input-primary" : status === "error"  ?"input-error" : ""}`} 
        >
          {value}
        </textarea>
      <label 
          htmlFor={name ?? "default"} 
          className={`absolute left-0 top-1  transition-all ease-in-out ${isFocused || value !== "" ? "text-xs -top-4 " : "" } px-4 text-gray-600 ${status==="primary" ? "peer-focus:text-green-700" : "peer-focus:text-red-700"}`}
          >
          {label ?? "Label"}
        </label>
    </div>
  );
};

export default TextareaField;
