import React, {useState} from 'react'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import format from 'date-fns/format';

import { BiFilter } from "react-icons/bi";
import { axiosInstance } from '../../../config';

function Filter({setSchedule, setTotalPages, setLinkDownload}) {
   
   const[showFilter, setShowFilter] = useState(false)
   const[loading, setLoading] = useState(false)

   const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);

  const handleSubmitFilter = async () => {
   const startDate = format(state[0].startDate, 'yyyy-MM-dd');
   const endDate = format(state[0].endDate, 'yyyy-MM-dd');
   const url = `schedule?p=${1}&perPage=${10}&start_date=${startDate}&end_date=${endDate}`
      setLinkDownload(`print-schedule?start_date=${startDate}&end_date=${endDate}`)
   setLoading(true)
   await axiosInstance.get(url)
         .then(({data}) => {
            setLoading(false)
            setShowFilter(false)
            setSchedule(data.data)
            setTotalPages(data.pagination.total_pages)
         })
         .catch(err => {
            setLoading(false)
            console.info(err)
         })

  }
    
  return (
    <div className='relative'>
      <div onClick={() => setShowFilter(!showFilter)} className='flex space-x-3 items-center bg-white px-6 py-3 rounded shadow cursor-pointer hover:bg-gray-200'>
         <BiFilter />
         <p className='text-sm font-semibold text-gray-700 leading-6'>Filter</p>
      </div>
      <div className={`absolute ${showFilter ? "block" : "hidden"} z-40 shadow-lg border-2 border-gray-200 rounded transition-all ease-in-out bg-white`}>
         <DateRangePicker
            onChange={item => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            locale={locales['id']}
            />
         <hr />
         <div className='flex items-center w-full justify-end p-3 space-x-3'>
            <button onClick={() => setShowFilter(false)} className="px-6 py-3 hover:bg-gray-200 rounded-sm text-sm">Batal</button>
            <button disable={loading} onClick={handleSubmitFilter} className={`btn-primary-admin ${loading && 'btn-loading btn-disabled'}`}>Filter</button>
         </div>
      </div>
    </div>
  )
}

export default Filter