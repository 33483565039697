import React, { useState, useEffect } from 'react';

const Toast = ({
    message = "Your message has been sent!",
    duration = 4000,
    type = "info"
  }) => {
  const [showToast, setShowToast] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <div className={`w-full flex justify-center items-center fixed bottom-5 ${showToast ? 'block'  : 'hidden'} `}>
       <div className={`rounded p-4 ${type === "info" ? 'bg-gray-700' : type === "success" ? 'bg-green-700' : type === "error" ? "bg-red-700" : "bg-red-700"}  shadow-lg max-w-[500px] min-w-[300px] flex items-center justify-center`}>
          <div className="max-w-[567px] text-white">{message}</div>
      </div>
    </div>

  );
};

export default Toast;
