import React, { useState, useEffect } from 'react';

function RealTimeClock() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const options = {
   weekday: 'long',
   year: 'numeric',
   month: 'long',
   day: 'numeric',
   hour: 'numeric',
   minute: 'numeric',
   second: 'numeric',
   hour12: false,
   timeZone: 'Asia/Singapore'
 };

 const formatter = new Intl.DateTimeFormat('id-ID', options);

  return (
    <div className='fixed bottom-5 right-3 bg-[#2D3436] rounded shadow-xl w-[300px] z-50'>
     <div className='flex w-full justify-center items-center h-full p-4 text-white'>
         <h2>{formatter.format(date)}</h2>
     </div>
    </div>
  );
}

export default RealTimeClock;
