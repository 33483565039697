import React from 'react'
import {Link} from "react-router-dom"

function TopBar() {
  return (
   <div className="w-full flex justify-center align-center bg-white fixed z-50">
      <Link to="/">
         <img src="/images/logo.png" />
      </Link>
   </div>
  )
}

export default TopBar