import { useState, useEffect } from "react"

import ModalCreate from "../../../components/admin/schedule/ModalCreateSchedule"
import ModalEdit from "../../../components/admin/schedule/ModalEdit";
import {axiosInstance, BASEURL} from "../../../config";
import Loading from "../../../components/Loading";
import RowData from "../../../components/admin/schedule/RowData";
import Modal from "../../../components/admin/Modal";
import Pagination from "../../../components/admin/Pagination";
import SearchBar from "../../../components/admin/schedule/SearchBar";
import Filter from "../../../components/admin/schedule/Filter";

const Schedule = () => {

	const[loading, setLoading]	= useState(false)
	const[schedule, setSchedule] = useState([])
	const[idDeleteSelected, setIdDeleteSelected] = useState(null)
   	const[showDeleteConfirm, setShowDeleteConfirm] = useState(false)
	const[showModalEdit, setShowModalEdit] = useState(false)
	const[idEditSelected, setIdEditSelected] = useState(null)
	const[searchQuery, setSearchQuery] = useState("")
	const[loadingSearch, setLoadingSearch] = useState(false)

	const[currentPage, setCurrentPage] = useState(1);
	const[itemsPerPage, setItemsPerPage] = useState(10);
	const[totalPages, setTotalPages] = useState(null);

	const[linkDownload, setLinkDownload] = useState("print-schedule?all=true");

	useEffect(() => {
		document.title = "Admin | Jadwal"
		getSchedlue()
	}, [currentPage, itemsPerPage])

	const getSchedlue = async () => {
		setLoading(true)
		await axiosInstance.get(`schedule?p=${currentPage}&perPage=${itemsPerPage}`)
			.then(({data}) => {
				setLoading(false)
				setTotalPages(data.pagination.total_pages)
				setSchedule(data.data)
			})
			.catch(err => {
				setLoading(false)
				console.info(err)
			})
	}

	const handlePushData = form => {
		getSchedlue()
	}

	const handleCloseModalConfirmDelete = () => {
		setShowDeleteConfirm(false)
	}

	const handleOpenModalConfirmDelete = () => {
		setShowDeleteConfirm(true)
	}

	const handleDelete = async () => {
      if(idDeleteSelected !== null)
		{
			await axiosInstance.delete(`schedule/${idDeleteSelected}`)
				.then(({data}) => {
					const filteredData = schedule.filter((item) => item.id !== idDeleteSelected)
					setSchedule(filteredData)
					handleCloseModalConfirmDelete()
				})
				.catch(err => {
					console.info(err)
				})
		}	
   }

	const handleChangePage = (pageNumber) => {
		setCurrentPage(pageNumber)
	}

	const handleChangeCurrentPage = event => {
		const value = Number(event.target.value)
		setItemsPerPage(value)
	}

	const handleEdit = id => {
		setShowModalEdit(true)
		setIdEditSelected(id)
	}

	const handleSearch = async event => {
		if(event.key === "Enter") {
			if(searchQuery === "" || loadingSearch === true) return;

			setLoadingSearch(true)
			await axiosInstance.get(`schedule?p=${currentPage}&perPage=${itemsPerPage}&q=${searchQuery}`)
				.then(({data}) => {
					setLoadingSearch(false)
					setTotalPages(data.pagination.total_pages)
					setSchedule(data.data)
				})
				.catch(err => {
					setLoadingSearch(false)
					console.info(err)
				})
			
		}
	}

	const handleSearchByButton = async () => {
		if(searchQuery === "" || loadingSearch === true) return;

			setLoadingSearch(true)
			await axiosInstance.get(`schedule?p=${currentPage}&perPage=${itemsPerPage}&q=${searchQuery}`)
				.then(({data}) => {
					setLoadingSearch(false)
					setTotalPages(data.pagination.total_pages)
					setSchedule(data.data)
				})
				.catch(err => {
					setLoadingSearch(false)
					console.info(err)
				})
	}

	const handleClearSearch = () => {
		setSearchQuery("")
		getSchedlue()
	}


	return(
		<>
		<Modal isOpen={showDeleteConfirm} onClose={handleCloseModalConfirmDelete} handleDelete={handleDelete} />
			<div className="mt-6 rounded">
				<div className="flex justify-between items-center">

					<div className="flex space-x-3 items-center">
						<SearchBar 
							value={searchQuery} 
							setSearchQuery={setSearchQuery} 
							handleSearch={handleSearch} 
							loading={loadingSearch}
							handleSearchByButton={handleSearchByButton}
							handleClearSearch={handleClearSearch}
						/>
						<Filter setSchedule={setSchedule} setLinkDownload={setLinkDownload} />
					</div>

					<div className="flex items-center space-x-2">
						<a className="btn-ghost-primary-admin flex space-x-2 items-center" target="_blank" href={BASEURL + linkDownload}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
								  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
								</svg>
							<span className="block">Download</span>
						</a>
						<ModalCreate getSchedlue={getSchedlue} setTotalPages={setTotalPages} />
					</div>
				</div>
				<hr />
				
				<div className="relative sm:rounded-lg  min-h-[440px] bg-white">
				    <table className="w-full text-xs text-left text-gray-500 dark:text-gray-400">
				        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
				            <tr>
				                <th scope="col" className="px-6 py-3">
				                    No
				                </th>
				                <th scope="col" className="px-6 py-3">
				                    Agenda Rapat
				                </th>
				                <th scope="col" className="px-6 py-3">
				                    Tempat
				                </th>
				                <th scope="col" className="px-6 py-3">
				                    Tanggal
				                </th>
				                <th scope="col" className="px-6 py-3">
				                    Jam
				                </th>
				                <th scope="col" className="px-6 py-3">
				                    Aksi
				                </th>
				            </tr>
				        </thead>
				        <tbody>
				           
							{
								loading ? (
									<tr>
										<td colSpan="7" >
											<div className="h-full bg-gray-100 flex justify-center items-center w-full">
												<Loading />
											</div>
										</td>
									</tr>		
								): (
									<>
										{
											schedule.length > 0 ? (
												<>
													{
														schedule.map((item, index) => {
															let numberRow = index + 1 + (itemsPerPage * (currentPage - 1 ));
															return(
																<RowData 
																	key={index} 
																	data={item} 
																	index={numberRow} 
																	showModalConfirm={handleOpenModalConfirmDelete} 
																	setIdDeleteSelected={setIdDeleteSelected} 
																	handleEdit={handleEdit}
																/>
															)
														})
													}
												</>
											):(
												<EmptyData />
											)
										}	
									</>
								)
							}						
				        </tbody>
				    </table>
				</div>
							
				{
					schedule.length > 0 &&(
						<Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handleChangePage} handleChangeCurrentPage={handleChangeCurrentPage} />
					)
				}

			</div>
			<br />
			{
				showModalEdit && <ModalEdit id={idEditSelected} setShowModalEdit={setShowModalEdit} getSchedlue={getSchedlue} />
			}
		</>
	)
}

export default Schedule;

const EmptyData = () => {
	return(
		<tr>
			<td colSpan="7" className="text-center py-10 bg-gray-100">
				<div className="h-[200px] flex w-full justify-center items-center flex-col">
					<img src="/images/no-data.png" alt="data kosong" width="150" />
					<h4 className="mt-4 text-[14px] text-berkeley-blue">Tidak ada data</h4>
				</div>
			</td>
		</tr>
	)
}