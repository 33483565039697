import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// pages
import Home from "./pages/Home"
import Login from "./pages/Login"

// admin page
import Layout from "./pages/admin/Layout"
import Dashboard from "./pages/admin/Dashboard"

// Schedule
import Schedule from "./pages/admin/schedule/Schedule"

// page not found
import NotFound from "./pages/NotFound"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="font-Poppins">
      <Router>
        <Routes>
          <Route path="/" element={<App />} >
            <Route index element={<Home />} />
          </Route>
          <Route path="/in" element={<Layout />} >
            <Route index element={<Dashboard />} />
            <Route path="schedule" element={<Schedule />} />
          </Route>

          <Route path="/auth" element={<Login />}  />
          <Route path='*' element={<NotFound />}/>
        </Routes>
      </Router>
    </div>
  </React.StrictMode>
);
