import React from 'react'
import { BiSearchAlt2, BiX } from "react-icons/bi";
import Loading from '../../Loading';


function SearchBar({value, setSearchQuery, handleSearch, loading, handleSearchByButton, handleClearSearch}) {
  return (
   <div  className="w-[428px] bg-white rounded shadow h-full my-2 flex items-center justify-center">
      <input
         value={value}
         onKeyDown={e => handleSearch(e)}
         onChange={e => setSearchQuery(e.target.value)}
         type="text" 
         placeholder="agenda, tempat, hari..." 
         className="w-full px-4 py-3 focus:ring-blue-500 focus:border-blue-500 focus:outline-none text-gray-700" 
      />
      {
         value !== "" && <span onClick={handleClearSearch} className='p-1 hover:bg-gray-100 rounded-full mr-1'>
            <BiX className='w-6 h-6 text-gray-700' />
         </span>
      }
      <button onClick={handleSearchByButton} disabled={loading} className={`p-2 bg-berkeley-blue rounded-sm mr-1  ${loading ? "opacity-60 cursor-not-allowed" : "hover:opacity-90"}`}>
         {
            loading ? <Loading size='xs' /> :  <BiSearchAlt2 className="w-5 h-5 text-white" />
         }
         
      </button>
   </div>
  )
}

export default SearchBar