import React, {useEffect, useState} from "react"
import RealTimeClock from "../components/RealTimeClock";
import {axiosInstance} from "../config";

const Home = () => {

	const[schedule, setSchedule] = useState([])
	const[loading, setLoading] = useState(false)

	useEffect(() => {
		getSchedule()
	}, [])

	const getSchedule = async () => {
		setLoading(true)
		await axiosInstance.get("schedule-today")
			.then(({data}) => {
				setSchedule(data.data)
				setLoading(false)
			})
			.catch(err => {
				console.info(err)
				setLoading(false)
			})
	}

	return (
		<>
			<RealTimeClock />
			<div className="min-h-screen w-full bg-gray-100 pt-[100px]">
			<div className="container mx-auto md:px-20 px-4">
				<div className="w-full text-center font-bold text-black text-2xl">
					<h1>LAYANAN INFORMASI AGENDA PIMPINAN </h1>
					<h1>UNIVERSITAS MUHAMMADIYAH KENDARI</h1>
					<h1>SEKRETARIAT REKTOR</h1>
				</div>

				<div className="mt-6">
					<table className="table-auto w-full text-sm">
					  <thead>
					    <tr className="bg-[#016336] text-white">
					      <th className="px-4 py-2">No</th>
					      <th className="px-4 py-2">Tanggal</th>
					      <th className="px-4 py-2">Jam</th>
					      <th className="px-4 py-2">Acara</th>
					      <th className="px-4 py-2">Tempat</th>
					      <th className="px-4 py-2">Ket.</th>
					    </tr>
					  </thead>
					  <tbody>
					   {
							schedule?.map((item, index) => (
								<tr key={index} className="bg-gray-100">
									<td className="border px-4 py-2 ">{index + 1}</td>
									<td className="border px-4 py-2 w-[200px]">{item?.tanggal_indonesia}</td>
									<td className="border px-4 py-2 w-[150px] text-center">{item?.jam}</td>
									<td className="border px-4 py-2 ">{item?.nama_agenda}</td>
									<td className="border px-4 py-2">{item?.tempat}</td>
									<td className="border px-4 py-2">{item?.keterangan}</td>
								</tr>
							))
						}
					  </tbody>
					</table>

				</div>
			</div>
		</div>
		</>
	)
}

export default Home;