import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.umkendari.ac.id/arw/',
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('_tokenarw');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const BASEURL = "https://api.umkendari.ac.id/arw/";
// const BASEURL = 'http://localhost:3001/in/schedule';


export {axiosInstance, BASEURL};
