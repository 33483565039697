
import { useState, useEffect, useRef } from "react"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import format from 'date-fns/format';
import id from 'date-fns/locale/id';

const InputFieldIcon = ({label = "Default", name="", type = "date", icon = "", value = "", onChanges}) => {

	const ref = useRef(null);

	const	[showDate, setShowDate] = useState(false)
	
	const [time, setTime] = useState(new Date().toLocaleTimeString());
	const [date, setDate] = useState(new Date());

  const [times, setTimes] = useState({
  	hours: 0,
  	minutes: 0,
  	ampm: "AM"
  })

	useEffect(() => {
   	document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });
	
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowDate(false);
    }
  };

	const handleChange = (name, date) => {
	   setDate(date);
	 	const formattedDate = format(date, 'yyyy-MM-dd');
	   setShowDate(false)
	   onChanges({
	   	name,
	   	value: formattedDate
	   })
	 };	

	const handleClick = () => {
		setShowDate(!showDate)
	}

	const handleChangeInputTime = (event) => {
    setTime(event.target.value);
    
  };

  const handleSelectTimes = (event) => {
  	setTimes({...times, [event.target.name]: event.target.value})
  }
	const formattedIdDate = format(date, 'EEEE, dd MMMM yyyy', { locale: id });

	if(type === "date") {

		return (
			<>
			<span className="text-gray-900 text-sm block mb-2">{label}</span>
				<div className="relative">
					<div onClick={handleClick}  className="w-full flex justify-between items-center p-4 bg-gray-50 rounded hover:bg-gray-100 cursor-pointer">
						<span className="text-gray-800 text-sm">{formattedIdDate}</span>
						{icon}
					</div>
					<div className={`absolute ${showDate ? "block" : "hidden"} w-full h-[235px] rounded shadow left-0 -bottom-[238px]`}>
						<div style={{ position: 'relative', zIndex: 999 }} className="shadow-xl rounded-md overflow-hidden">
							<Calendar
					        onChange={e => handleChange('date', e)}
					        value={value}
					        locale={id.locale}
					        type="datetime"
					        formatShortWeekday={(locale, date) =>
					          format(date, 'E', { locale: locale })
					        }
					        formatMonthYear={(locale, date) =>
					          format(date, 'MMM yyyy', { locale: locale })
					        }
					        formatMonth={(locale, date) =>
					          format(date, 'MMMM', { locale: locale })
					        }
					        formatWeekdayLong={(locale, date) =>
					          format(date, 'EEEE', { locale: locale })
					        }
					        formatWeekdayShort={(locale, date) =>
					          format(date, 'EEE', { locale: locale })
					        }
					        formatDay={(locale, date) =>
					          format(date, 'd', { locale: locale })
					        }
					        formatMonthYearHeader={(locale, date) =>
					          format(date, 'MMMM yyyy', { locale: locale })
					        }
					      />
						</div>
					</div>
				</div>
			</>
		)
	}else if(type === "time") {
		return(
			<div ref={ref}>
			<span className="text-gray-900 text-sm block mb-2">{label}</span>
				<div className="relative">
					<div onClick={handleClick}  className="w-full flex justify-between items-center p-4 bg-gray-50 rounded hover:bg-gray-100 cursor-pointer">
						<span className="text-gray-800 text-sm">{times.hours + " : " + times.minutes + " : " + " 00 " +times.ampm}</span>
						{icon}
					</div>
					<div className={`absolute bg-white ${showDate ? "block" : "hidden"} rounded shadow z-10 left-0 -bottom-[50px] border-[1px] border-gray-300`}>
							
						<div className="flex space-x-2 w-full px-5 py-3 items-center">
					      <select name="hours" value={times.hours} onChange={handleSelectTimes} className="rounded p-2">
					        {[...Array(13)].map((_, i) => (
					          <option key={i + 1} value={i}>
					            {i.toString().padStart(2, '0')}
					          </option>
					        ))}
					      </select>
					      <select name="minutes" value={times.minutes} onChange={handleSelectTimes} className="rounded p-2">
					        {[...Array(61)].map((_, i) => (
					          <option key={i} value={i}>
					            {i.toString().padStart(2, '0')}
					          </option>
					        ))}
					      </select>
					      <select name="ampm" value={times.ampm} onChange={handleSelectTimes} className="rounded p-2">
					          <option value="AM" checked={times.ampm === "AM"}>AM</option>
					          <option value="PM" checked={times.ampm === "PM"}>PM</option>
					      </select>
					    </div>

					</div>
				</div>
			</div>
		)
	}
	
}


export default InputFieldIcon;