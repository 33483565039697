import React, { useState, useEffect } from 'react';
import { BiX, BiCalendar } from "react-icons/bi";

import InputField from "../../forms/InputField"
import TextareaField from "../../forms/TextareaField"

import LoadingScreen from "../../LoadingScreen"
import { axiosInstance } from "../../../config"
import 'react-calendar/dist/Calendar.css';

const ModalEdit = ({id, setShowModalEdit, getSchedlue}) => {
   useEffect(() => {
      document.body.style.overflow = 'hidden';
      getDataScheduleById()
    },[]);
  const [loading, setLoading] = useState(false);

   const[form, setForm] = useState({
      id : "",
      title : "",
      place : "",
      description: "",
      date: "",
      timeStart: "",
      timeEnd: ""
   })


  const getDataScheduleById = async () => {
   setLoading(true)
   await axiosInstance.get(`/schedule/${id}`)
         .then(({data}) => {
            const{nama_agenda, tempat, tanggal, times, keterangan, id} = data.data
            setForm({
               id:  id,
               title:  nama_agenda,
               place: tempat,
               date: tanggal,
               timeStart: times.timeStart,
               timeEnd: times.timeEnd,
               description: keterangan
            })
            setLoading(false)
            console.info(data)
         })
         .catch(err => {
            console.info(err)
            setLoading(false)
         })
  }

  const handleInputChange = event => {
      setForm({...form, [event.target.name]: event.target.value})
  }

  const handleSubmitForm = async () => {
    setLoading(true)
    await axiosInstance.put(`schedule/${form.id}`, {form})
          .then((res) => {
            setLoading(false)
            setShowModalEdit(false)
            getSchedlue()
            setForm({
              title : "",
              place : "",
              description: "",
              date: "",
              timeStart: "",
              timeEnd: ""
            })
          }).catch(err => {
            setShowModalEdit(false)
            setLoading(false)
          })
  }


  return (

   <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
         <div className="h-screen bg-gray-50 ">
            <div className="w-full h-[63px] bg-white flex items-center px-2 border-b-[1px] border-gray-200 justify-between">
               <div className="flex items-center">
               <div onClick={() => setShowModalEdit(false)} className="m-2 hover:bg-gray-100 rounded-full p-2 cursor-pointer">
                  <BiX className="h-8 w-8 text-gray-600" />
               </div>

               <div className="bg-green-100 rounded-full p-2">
                  <BiCalendar className="h-7 w-7 text-green-800" />
               </div>
               <h2 className="text-gray-600 font-light text-xl ml-2">{form.title}</h2>
               </div>
               <button className="btn-primary-admin mr-5" onClick={handleSubmitForm}>
                  Ubah agenda
               </button>
            </div>

            {
               loading ? <LoadingScreen />
               :
               (
                  <div className="w-full flex">
               
                     <div className="w-[70%] p-6">
                     <div className="w-full  bg-white rounded-lg border-[1px] border-gray-200 p-8">
                        
                        <div className="mb-8 w-full">
                           <InputField 
                              name="title" 
                              label="Judul agenda"
                              value={form.title}
                              onChange={handleInputChange}
                           />
                        </div>

                        <div className="mb-8 w-full">
                           <TextareaField
                              name="place" 
                              label="Tempat"
                              rows={4}
                              value={form.place}
                              onChange={handleInputChange}
                           />
                        </div>

                      <div>
                      <span className="text-gray-900 text-sm block mb-2 ">Keterangan</span>
                        <select onChange={handleInputChange} name="description" className='w-full p-3 rounded-sm border-[1px] focus:border-green-600'>
                           <option value="">--pilih--</option>
                           <option value="terjadwal" selected={form.description === 'terjadwal'}>Terjadwal</option>
                        </select>
                      </div>
                     </div>
                     </div>

                     <div className="w-[30%] h-[562px] bg-white border-l-[1px] py-6 px-5">
                     <p className="text-gray-600 font-light"> Pengaturan waktu</p>

                        <div className="mb-8 mt-4 w-full relative">
                        <span className="text-gray-900 text-sm block mb-2">Tanggal</span>
                           <input type="date" className='input' value={form.date} name="date" onChange={handleInputChange} />
                        </div>

                        <div className="mb-8 mt-4 w-full relative">
                           <span className="text-gray-900 text-sm block mb-2">Jam mulai</span>
                           <input type="time" className='input' value={form.timeStart} name="timeStart" onChange={handleInputChange} />
                        </div>
                        <div className="mb-8 mt-4 w-full relative">
                           <span className="text-gray-900 text-sm block mb-2">Jam Selesai</span>
                           <input type="time" className='input' value={form.timeEnd} name="timeEnd" onChange={handleInputChange} />
                        </div>
                     </div>
                  </div>
               )
            }

            

         </div>
      </div>
      
   
</>
  );
};

export default ModalEdit;
