import { useState } from "react"
import {Outlet} from "react-router-dom"
import { AppContext } from "../../context/app-context"

import TopBar from "../../components/admin/TopBar"

const Layout = () => {

	 const[showSidebar, setShowSiderbar] = useState(true)

	  const appContextValue = {
	    showSidebar,
	    setShowSiderbar
	  }

	return(
		<div className="font-Poppins">
			<AppContext.Provider value={appContextValue}>
				<TopBar />
				<div className="container mx-auto bg-[#edede9] px-20 min-h-screen pt-[75px]">
					<Outlet />
				</div>
     		</AppContext.Provider>
		</div>
	)
}

export default Layout;