import React, {useState, useRef, useEffect} from 'react';
import { BiCaretDown,BiX } from "react-icons/bi";

const SelectField = (
    { options = [
        {value : 'terjadwal', label: 'Terjadwal'},
      ], 
      form, setForm, onChange = null, value = "", name
  }) => {

  const ref = useRef(null);
  const[showOption, setShowOption] = useState(false)
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowOption(false);
      setFocused(false)
    }
  };

  const handleClick = (event) => {
    const attributeValue = event.currentTarget.getAttribute('data-value');
    onChange(attributeValue);
  }

  const handleDivClick = () => {
    setShowOption(!showOption)
    setFocused(true)
  };

  const handleClearValue = (event) => {
    event.stopPropagation();
    setForm({...form, ["description"]: ""})
  }

  return(
      <>
        <div
          ref={ref} 
          onClick={handleDivClick}
          className={`relative w-full flex items-center p-4  ${focused === true || value !== "" ? " border-b-2 border-green-700" : "border-b-[1px] border-gray-500"} hover:bg-gray-100 rounded-top-xl cursor-pointer justify-between relative transition-colors bg-gray-50 `}>
          <span className={`absolute transition-all ease-in-out block ${focused || value !== "" ? " text-xs -top-4 text-green-700 " : ""}`}>Keterangan</span>
          <span>{value}</span>
          <span className="flex space-x-2 items-center">
            {value !== "" && <BiX onClick={handleClearValue} className="h-7 w-7 text-gray-600 hover:text-red-700" />}
            <BiCaretDown />
          </span>

          {
            showOption && (
              <div className={`absolute w-full h-[140px] overflow-y-scroll z-10 left-0 -bottom-[142px] rounded shadow-xl bg-white p-4 border-[1px] border-gray-200 transition-all ease-in-out delay-100 `}>
                {
                  options.map((option, index) => (
                    <span key={index} 
                      className="block p-2 hover:bg-gray-100 rounded text-xs"
                      data-value={option.value}
                      onClick={handleClick}
                    >
                    {option.label}
                    </span>
                  ))
                }
            </div>
            )
          }
        </div>
      </>
  )
}

  
export default SelectField;
