import React, { useState, useEffect } from 'react';
import { BiX, BiCalendar } from "react-icons/bi";

import InputField from "../../forms/InputField"
import TextareaField from "../../forms/TextareaField"
import SelectField from "../../forms/SelectField"
import InputFieldIcon from "../../forms/InputFieldIcon"
import format from 'date-fns/format';

import LoadingScreen from "../../LoadingScreen"
import { axiosInstance } from "../../../config"

const ModalCreateSchedule = ({getSchedlue}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const[deskripsi, setDeskripsi] = useState("")

  const formattedDate = format(new Date(), 'yyyy-MM-dd');
  const[form, setForm] = useState({
    title : "",
    place : "",
    description: "",
    date: formattedDate,
    timeStart: "",
    timeEnd: ""

  })

  const handleInputChange = event => {
      setForm({...form, [event.target.name]: event.target.value})
  }

  const handleInputDateWithIcon = event => {
    setForm({...form, [event.name]: event.value})
  }

  const handleSubmitForm = async () => {
    setLoading(true)
    await axiosInstance.post("schedule", form)
          .then((res) => {
            setLoading(false)
            setIsOpen(false)
            getSchedlue()
            setForm({
              title : "",
              place : "",
              description: "",
              date: formattedDate,
              timeStart: "",
              timeEnd: ""
            })
          }).catch(err => {
            setLoading(false)
          })
  }

  return (
    <div className="relative">
     <button onClick={handleOpen} className="btn-primary-admin">Tambah jadwal</button>
      {
        isOpen && (
           <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="h-screen bg-gray-50 ">
              <div className="w-full h-[63px] bg-white flex items-center px-2 border-b-[1px] border-gray-200 justify-between">
                <div className="flex items-center">
                  <div onClick={handleClose} className="m-2 hover:bg-gray-100 rounded-full p-2 cursor-pointer">
                    <BiX className="h-8 w-8 text-gray-600" />
                  </div>

                  <div className="bg-green-100 rounded-full p-2">
                    <BiCalendar className="h-7 w-7 text-green-800" />
                  </div>
                  <h2 className="text-gray-600 font-light text-xl ml-2">Tambah agenda</h2>
                </div>
                <button className="btn-primary-admin mr-5" onClick={handleSubmitForm}>
                  Simpan agenda
                </button>
              </div>

              <div className="w-full flex">
                <div className="w-[70%] p-6">
                  <div className="w-full  bg-white rounded-lg border-[1px] border-gray-200 p-8">
                    
                    <div className="mb-8 w-full">
                        <InputField 
                          name="title" 
                          label="Judul agenda"
                          value={form.title}
                          onChange={handleInputChange}
                        />
                    </div>

                     <div className="mb-8 w-full">
                        <TextareaField
                          name="place" 
                          label="Tempat"
                          rows={4}
                          value={form.place}
                          onChange={handleInputChange}
                        />
                    </div>

                    <SelectField name="address" value={form.description} form={form} setForm={setForm} onChange={e => setForm({...form, ["description"]: e})} />
                  </div>
                </div>

                <div className="w-[30%] h-[562px] bg-white border-l-[1px] py-6 px-5">
                  <p className="text-gray-600 font-light"> Pengaturan waktu</p>

                    <div className="mb-8 mt-4 w-full relative">
                        <InputFieldIcon 
                          label="Tanggal"
                          name="date"
                          type="date"
                          icon={<BiCalendar className="text-gray-700 w-6 h-6" />}
                          onChanges={handleInputDateWithIcon} 
                        />
                    </div>

                    <div className="mb-8 mt-4 w-full relative">
                      <span className="text-gray-900 text-sm block mb-2">Jam mulai</span>
                      <input type="time" className='input' value={form.timeStart} name="timeStart" onChange={handleInputChange} />
                    </div>
                    <div className="mb-8 mt-4 w-full relative">
                      <span className="text-gray-900 text-sm block mb-2">Jam Selesai</span>
                      <input type="time" className='input' value={form.timeEnd} name="timeEnd" onChange={handleInputChange} />
                    </div>
                </div>
              </div>

            </div>
          </div>
        )
      }
      {
        loading && <LoadingScreen />
      }
    </div>
  );
};

export default ModalCreateSchedule;
