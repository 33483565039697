import { useState } from "react"
import {Link} from "react-router-dom"
import { axiosInstance, BASEURL } from "../config"
import Loading from "../components/Loading"
import Toast from "../components/Toast"

const Login = () => {

  const[username, setUsername] = useState("")
  const[password, setPassword] = useState("")
  const[showPassword, setShowPassword] = useState(false)
  const[loading, setLoading] = useState(false)
  const[errors, setErrors] = useState({
    type: "",
    message: ""
  })

  const handleSubmit = async e => {
        e.preventDefault();
    setLoading(true)

    try {
      const {data} = await  axiosInstance.post("/login", {username, password})
      localStorage.setItem('_tokenarw', data.token);
      window.location.href = "https://agendarektor.umkendari.ac.id/in/schedule";
      setLoading(false)
    } catch (error) {
      setLoading(false)
      const {status, message} = error.response.data

      setErrors({...errors, type: status, message: message })

      setTimeout(() => {
         setErrors({...errors, type: "", message: "" })
      }, 6000)
      
    }

   

  }

	return (
		<div className="h-screen bg-white">
      {
        errors.type !== "" && <Toast message={errors.message} type={errors.type} />
      }
			<div className="w-full flex justify-center align-center">
				<Link to="/">
					<img src="/images/logo.png" />
				</Link>
			</div>
			<hr />
			<div className="container mx-auto">
				<div className="w-full flex justify-center py-10 align-center">
					<form onSubmit={handleSubmit} className="bg-white relative rounded px-8 pt-6 pb-8 mb-4 border-[1px] border-gray-200 w-[420px]">

            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="username"
              >
                Username
              </label>
              <input
                required
                className="input"
                id="username"
                type="text"
                onChange={e => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <input
                required
                className="input"
                id="password"
                type={showPassword ? "text" : "password"}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="flex relative items-center">
              <input
                onChange={() => setShowPassword(!showPassword)}
                type="checkbox" className="mr-4 p-4 h-4 w-4 text-green-700" id="show-password" />
              <label className="abolute text-sm text-gray-800" htmlFor="show-password">Tampilkan sandi</label>
            </div>
            <div className="w-full mt-6">
              <button
                disabled={false}
                className="btn btn-green btn-block btn-block-xl"
                type="sumbit"
              >
                Login
              </button>
            </div>

            {
              loading && (
                  <div className="top-0 bottom-0 right-0 left-0 z-10 bg-gray-100 opacity-50 absolute flex items-center justify-center" >
                    <Loading />
                  </div>
              )
            }

          </form>
				</div>
			</div>
		</div>
	)
}

export default Login;